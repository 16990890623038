import React, { useEffect, useRef, useState } from "react";
import Loaders from "../Loaders";
import HeaderTop from "../HeaderTop";
import Menus from "../Menus";
import Footers from "../Footers";
import '../../../libs/css/bootstrap.min3.3.7.css';
import '../../../libs/css/sweetalert2.min.css';
import { API_ROUTES } from "../../../utils/constants";
import Nulls from "../Nulls";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import UnSubscriptionTable from "./UnSubscriptionTable";
import Swal from "sweetalert2";


export default function UnSubscription(props){
    const {objectToFormData, errorFunction, Helmet, capitalizeWords, isAuthExpired, today, resetTimeToMidnight, resetTimeToB4Midnight, formatDate, allCPs } = props;

    // console.log("allCPssssaa");
    // console.log(allCPs);

    useEffect(() => {document.title = "UnSubscription | Ringo"}, []);

    const [isLoading, setIsLoading] = useState(true);
    const [dataTotal, setDataTotal] = useState(0);
    const [dataList, setDataList] = useState([]);
    const [columns, setColumns] = useState([]);
    const [page, setPage] = useState(1);
    const countPerPage = 20;
    const [isFade, setIsFade] = useState(true);
    const [cpSelected, setCpSelected] = useState('');
    const [registerName, setRegisterName] = useState('service');
    const [isLoading1, setIsLoading1] = useState(true);
    const clearInputs = useRef(null);


    const columns1 = [
        {   
            name: 'Bearer ID',
            selector: row => row.bearerId ? capitalizeWords(row.bearerId) : <Nulls />,
            sortable: true,
        },
        {
            name: 'Counts',
            selector: row => row.count ? row.count.toLocaleString() : <Nulls />,
            sortable: true,
        },
    ];


    useEffect(() => {
        setColumns(columns1);
    }, []);

    const userData = JSON.parse(localStorage.getItem('userDataFs'));


    const getData = async (isLoad=true, myFilters) => {
        const extractID = myFilters.cp_id.split("|")[0];
        const extractCP = myFilters.cp_id.split("|")[1];
        setCpSelected(extractCP);
        setIsLoading1(true);

        let dataString = {};
        if(myFilters){
            dataString = { 
                cp_id: myFilters.cp_id ? extractID > 0 ? extractID : '' : '',
                fro: myFilters.fro,
                to: myFilters.to,
            } 
        }else{
            dataString = { 
                cp_id: myFilters.cp_id ? extractID > 0 ? extractID : '' : '',
                fro: resetTimeToMidnight(formatDate(today)),
                to: resetTimeToB4Midnight(formatDate(today)),
            };
        }

        // console.log("chibobo");
        // console.log(dataString);
        setIsFade(true);
        try {
            const response = await fetch(API_ROUTES.UNSUBSCRIPTION, {
                method: "POST",
                body: objectToFormData(dataString),
                headers: {'token': userData?.responseDetails?.token},
            });
            const responseJSON = await response.json();
            isAuthExpired(responseJSON); // check for expired login

            if (responseJSON.status.toString() === "200") {

                // console.log("responseJSON===");
                // console.log(responseJSON);

                const responseData = responseJSON.data;
                const totalData = responseData.length;
                setDataTotal(totalData);
                setDataList(responseData);
                setIsLoading(false);
                setIsFade(false);
                setIsLoading1(false);
            }else{
                setDataTotal(0);
                setDataList([]);
                setIsLoading(false);
                setIsFade(false);
                setIsLoading1(false);
            }
        } catch (error) {
            console.log('Error getting data: ', error);
            setDataTotal(0);
            setDataList([]);
            setIsLoading(false);
            setIsFade(false);
            setIsLoading1(false);
        }
    }

    useEffect(() => {
        getData(true, { cp_id: "", fro: resetTimeToMidnight(formatDate(today)), to: resetTimeToB4Midnight(formatDate(today)) });
    }, [page]);

    
    const handleDuration = async (data) => {
        // console.log(data);
        if(data.cp_id === ""){
            Swal.fire({
                title: "Error",
                text: "Please select a CP",
                icon: "error",
            });
            return;
        }

        let dataString = { 
            cp_id: data.cp_id,
            fro: data.fro + " 00:00:00",
            to: data.to + " 23:59:59",
        };
        getData(true, dataString);
    }



    return (
        <>
            {/* <Loaders />
            <LoadingSpinner /> */}

            
                
                <div className="flapt-page-wrapper mainContent">
                    <Menus />

                    <div className="flapt-page-content">
                        <HeaderTop />

                        <div className="main-content introduction-farm mt--20">
                            <div className="content-wraper-area">
                                <div className="dashboard-area">
                                    <div className="container-fluid mb-90">
                                        <div className="card-header mb--30 mb-xs-10 ml-10">
                                            <h2>UnSubscription</h2>
                                        </div>
                                        {
                                        isLoading1 ?
                                        <LoadingSpinner />
                                        :
                                        <div className="row g-4">
                                            <div className="col-lg-7 col-md-8 pl-xs-0 pr-xs-0">
                                                <UnSubscriptionTable capitalizeWords={capitalizeWords} dataList={dataList} dataTotal={dataTotal} isLoading={isLoading} setIsLoading={setIsLoading} columns={columns} countPerPage={countPerPage} setPage={setPage} isFade={isFade} handleDuration={handleDuration} registerName={registerName} clearInputs={clearInputs} isAuthExpired={isAuthExpired} cpSelected={cpSelected} allCPs={allCPs} isLoading1={isLoading1} />
                                            </div>
                                        </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <Footers />
                        </div>
                    </div>
                </div>
            
            

            
        </>
    );
}